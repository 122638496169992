
/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../config";
import { authHeader } from "../services/auth";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

@Component({ components: { PerfectScrollbar } })
export default class Notification extends Vue {
  /* eslint-disable */
  public allCommunicationAndNotification: any = [];
  public message = null;
  public totalLength = 0;
  public currentLengthOnScroll = 0;
  public skip = 0;
  public showLoader = false;
  public modalLoader = false;
  public title = null;
  // public loanId = this.$route.query.id;
  public searchKeyword = "";
  public isReset = false;

  public async showAllNotificationAndCommunication(mountCall) {
    this.showLoader = true;
    try {
      this.skip = mountCall ? 0 : this.skip + 15;
      let path = "";
      path = "communication/getAllNotificationAndCommunication";
      const response = await Axios.get(BASE_API_URL + path, {
        params: {
          options: {
            skip: this.skip,
            limit: 15,
          },
          searchKeyword: this.isReset ? "" : this.searchKeyword,
        },
        headers: authHeader(),
      });

      if (response.status === 201) {
        this.isReset = false;
        if (mountCall) {
          this.allCommunicationAndNotification = response.data.communicationData
            ? response.data.communicationData
            : [];
        } else {
          const data = response.data.communicationData
            ? response.data.communicationData
            : [];
          this.allCommunicationAndNotification =
            this.allCommunicationAndNotification.concat(data);
        }
        this.totalLength = response.data.notificationlength;
        this.currentLengthOnScroll = this.allCommunicationAndNotification
          ? this.allCommunicationAndNotification.length
          : 0;
        // this.$emit("total-loan", this.totalLength);
      }
    } catch (error) {
      this.isReset = false;
      console.log(error);
    }
    this.showLoader = false;
  }

  public async fullView(id) {
    console.log(id, "id");
    this.modalLoader = true;
    try {
      const response = await Axios.get(
        BASE_API_URL + "communication/getSelectedNotification/" + id,
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        this.message = response.data.message;
        this.title = response.data.title;
        this.$modal.show("ViewNotificationModal");
      }
    } catch (error) {
      console.log(error);
    }
    this.modalLoader = false;
  }

  public async updateReadData(data) {
    const response = await Axios.post(
      BASE_API_URL + "communication/updateReadData",
      { notificationData: data },
      {
        headers: authHeader(),
      }
    );
    // if (!data.isRead) {
    //   EventBus.$emit("refreshNotificationCounter", true);
    // }
  }
  public hideModal() {
    this.$modal.hide("ViewNotificationModal");
    // EventBus.$emit("refreshNotificationCounter", true);
    this.showAllNotificationAndCommunication(true);
  }

  public backToBorrower() {
    this.$router.push({
      path: "/borrower-dashboard",
      query: { id: this.$route.query.id },
    });
  }

  public async scrollHandle(evt) {
    let scroll: any = this.$refs.scrollbar;
    if (
      !this.showLoader &&
      scroll.ps.reach.y == "end" &&
      // x.scrollTop + x.clientHeight <= x.scrollHeight &&
      this.currentLengthOnScroll < this.totalLength
    ) {
      await this.showAllNotificationAndCommunication(false);
    }
  }

  public async backToConditionalPage() {
    let id = this.$store.state.sessionObj.userId;
    const response = await Axios.get(
      BASE_API_URL + "communication/getOnBoardingDetail/",
      {
        headers: authHeader(),
      }
    );

    if (response.data) {
      if (
        response.data.onBoarding &&
        !response.data.onBoarding.isOnBoardingCompleted
      )
        this.$router.push({
          path: "/vendor-credentials",
          query: { id: this.$store.state.sessionObj.userId },
        });
      else this.$router.push("/broker-dashboard");
    }
  }
  public notificationEmailTo(data) {
    let toEmail: any = [];
    data.forEach((e) => {
      toEmail.push(e.email);
    });
    return toEmail.toString();
  }
  public notificationEmailCC(data) {
    let ccEmail: any = [];
    data.forEach((e) => {
      ccEmail.push(e.email);
    });
    return ccEmail.toString();
  }

  public async mounted() {
    console.log(this.$store.state.sessionObj.userId);
    await this.showAllNotificationAndCommunication(true);
  }
}
